<template>
  <div>
    <b-card class="w-100 d-flex rounded-lg">
      <b-row>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <h3 class="ml-1">Roles</h3>
          <div>
            <!-- <b-button variant="primary" class="rounded-pill mb-1" @click="$router.push({name:'assign_role'})">Assign Role</b-button>   -->
            <b-button
              variant="primary"
              class="rounded-pill mb-1 ml-1"
              @click="$router.push({name:'add_role'})"
            >Add New Role</b-button>
          </div>
        </div>

        <div v-if="load" class="text-center" style="width: 100%;">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <b-table v-else striped :items="items" select-mode="single" :fields="fields">
          <!-- @row-selected="onRowSelected" -->
          <template #cell(name)="data">{{data.item.name}}</template>
          <template #cell(actions)="data">
            <div class="d-flex align-items-center" v-if="data.item">
              <b-link
                class="cutom-alert-link"
                :to="{ name: 'permissions', params: { id: data.item.id} }"
                v-b-tooltip.hover.top="'Assign Permissions'"
              >
                <feather-icon icon="UnlockIcon" size="18" />
              </b-link>

              <feather-icon
                class="ml-1 cutom-alert-link"
                v-b-tooltip.hover.top="'Edit'"
                icon="EditIcon"
                size="18"
                @click="openEditModel(data.item)"
              />
            </div>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-modal
      id="modal-1"
      title="Edit Role"
      hide-footer
      v-model="editRoleModal"
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text>
        <b-form class="chat-app-form" @submit.prevent="submit">
          <validation-observer class="w-100" ref="roleForm" #default="{invalid}">
            <p class="h5">Role Name</p>
            <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input v-model="selected_role.name" placeholder="Enter role name" />
              </b-input-group>
              <small class="text-danger float-left">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </b-form>
        <b-button variant="primary" class="rounded-pill mt-2" type="submit">Save</b-button>
        <b-button class="rounded-pill mt-2 ml-1" @click="editRoleModal = false">Cancel</b-button>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BTable,
  VBTooltip,
  BSpinner,
  BModel,
  BForm,
  BInputGroup,
  BFormInput
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    vSelect,
    BTable,
    BSpinner,
    BModel,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
      items: "users/roles",
      load: "users/load"
    })
  },
  data() {
    return {
      editRoleModal: false,
      selected_role: null,
      fields: [
        // "show_details",
        { key: "name", label: "Name" },
        "Actions"
      ]
    };
  },
  methods: {
    openEditModel(role) {
      this.editRoleModal = true;
      this.selected_role = role;
    },
    submit() {
      const payload = {
        id: this.selected_role.id,
        query: { name: this.selected_role.name }
      };
      this.$store
        .dispatch("users/addRole", payload)
        .then(res => {
          // this.$router.push({name:'roles'})
          this.init();
          this.editRoleModal = false;
          this.$swal({
            icon: "success",
            title: `<h4>Role Updated Successfully</h4>`,
            showConfirmButton: true,
            confirmButtonColor: "#E84185",
            allowOutsideClick: true
          });
        })
        .catch(error => {
          if (error.response) {
            this.editRoleModal = false;
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          }
        });
    },
    init() {
      this.$store.dispatch("users/listRoles").then(_ => {});
    },
    onRowSelected(item) {
      this.$router.push({ name: "permissions", params: { id: item[0].id } });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
